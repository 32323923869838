import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ActionIcon, Box, DefaultProps, packSx, Popover } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';

import {
  useDeleteNotifications,
  useGetNotificationsInfinite,
  usePutNotifications,
} from 'api/notifications';

import { NotificationCountContext } from 'context';
import { NotificationIcon } from 'icons';
import { getHasHateoasAccess } from 'helpers';
import { useIsBrowserTabActive, useSearchParams, useServerErrors } from 'hooks';
import { NotificationType } from 'types';
import { DataWrapper } from 'components/shared';

import { InvitationFlowModal } from 'components/shared/Modals/InvitationFlowModal';
import { InvitationFlowModalContext } from 'context/InvitationFlowModal';
import { NOTIFICATION_TABS } from './consts';
import { getNotificationsCategoryByActiveTab } from './helpers';
import { NotificationsPanel } from './NotificationsPanel';
import { PulsatingDot } from './PulsatingDot';

type Props = {
  sx?: DefaultProps['sx'];
};

const NOTIFICATION_ID_SEARCH_PARAM = 'notificationId';

export const Notifications = ({ sx }: Props) => {
  const { addServerErrorActions } = useServerErrors();

  const { deleteSearchParam, getSearchParam } = useSearchParams([
    NOTIFICATION_ID_SEARCH_PARAM,
  ]);
  const notificationIdSearchParam = getSearchParam(
    NOTIFICATION_ID_SEARCH_PARAM,
  );

  const [notificationCount, setNotificationCount] = useContext(
    NotificationCountContext,
  );
  const { isModalOpen } = useContext(InvitationFlowModalContext);

  const [activeTab, setActiveTab] = useState<
    (typeof NOTIFICATION_TABS)[keyof typeof NOTIFICATION_TABS]
  >(NOTIFICATION_TABS.all);

  const isBrowserTabActive = useIsBrowserTabActive();

  const {
    mutateAsync: changeNotificationStatus,
    isLoading: isChangingNotificationStatus,
  } = usePutNotifications();
  const {
    mutateAsync: deleteNotifications,
    isLoading: isDeletingNotifications,
  } = useDeleteNotifications();
  const {
    data: notificationsData,
    isError: isNotificationsError,
    isInitialLoading: isNotificationsInitialLoading,
    fetchNextPage: fetchNotificationsNextPage,
    isFetchingNextPage: isFetchingNextNotificationsPage,
    isRefetching: isRefetchingNotifications,
  } = useGetNotificationsInfinite(
    {
      queryParams: {
        sortBy: 'read_at',
        sortDir: 'desc',
        category: getNotificationsCategoryByActiveTab(activeTab),
      },
    },
    {
      enabled:
        !isChangingNotificationStatus &&
        !isDeletingNotifications &&
        !notificationIdSearchParam &&
        isBrowserTabActive,
      keepPreviousData: true,
      refetchInterval: 30000,
      onSuccess: ({ pages }) => {
        const [firstPage] = pages;

        const { unreadCount, documentUnreadCount, organisationUnreadCount } =
          firstPage.summary;

        if (
          unreadCount !== null &&
          documentUnreadCount !== null &&
          organisationUnreadCount !== null
        ) {
          setNotificationCount(firstPage.summary);
        }
      },
    },
  );

  const listRef = useRef<HTMLUListElement>(null);

  const { ref: lastNotificationRef, entry: lastNotificationEntry } =
    useIntersection({
      root: listRef.current,
      threshold: 1,
    });

  const markNotificationAsRead = async (
    notificationId: NotificationType['id'],
    callback?: () => void,
  ) => {
    try {
      await changeNotificationStatus({
        read: [notificationId],
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      await changeNotificationStatus({
        markAllAsRead: true,
      });
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  const deleteNotification = async (id: NotificationType['id']) => {
    try {
      await deleteNotifications({
        queryParams: {
          ids: [id],
        },
      });
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  useEffect(() => {
    if (lastNotificationEntry?.isIntersecting) {
      fetchNotificationsNextPage();
    }
  }, [fetchNotificationsNextPage, lastNotificationEntry?.isIntersecting]);

  useEffect(() => {
    if (notificationIdSearchParam) {
      (async () => {
        try {
          await markNotificationAsRead(notificationIdSearchParam);
        } catch {
          // no action
        } finally {
          deleteSearchParam(NOTIFICATION_ID_SEARCH_PARAM);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationIdSearchParam]);

  const [opened, setOpened] = useState(false);

  const handleClosePopover = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <Popover width="100%" opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Box
          sx={[{ display: 'flex', position: 'relative' }, ...packSx(sx)]}
          onClick={() => setOpened(o => !o)}
        >
          <ActionIcon variant="transparent">
            <NotificationIcon color="primary.1" />
          </ActionIcon>
          {Boolean(
            notificationCount.unreadCount && notificationCount.unreadCount > 0,
          ) && (
            <PulsatingDot
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          maxWidth: 550,
          padding: 0,
        }}
      >
        <DataWrapper
          sx={theme => ({
            padding:
              isNotificationsError || isNotificationsInitialLoading
                ? theme.other.spacing(2)
                : undefined,
          })}
          data={notificationsData}
          isError={isNotificationsError}
          isLoading={isNotificationsInitialLoading}
        >
          {({ pages }) => {
            const notifications = pages.map(({ data }) => data).flat();
            const [notificationsDataFirstPage] = pages;
            const hasUpdateAccess = getHasHateoasAccess(
              'update',
              notificationsDataFirstPage.__links,
            );

            return (
              <NotificationsPanel
                notifications={notifications}
                lastNotificationRef={lastNotificationRef}
                notificationCount={notificationCount}
                hasUpdateAccess={hasUpdateAccess}
                isLoading={
                  isFetchingNextNotificationsPage ||
                  isRefetchingNotifications ||
                  isChangingNotificationStatus
                }
                markNotificationAsRead={markNotificationAsRead}
                markAllNotificationsAsRead={markAllNotificationsAsRead}
                deleteNotification={deleteNotification}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleClosePopover={handleClosePopover}
              />
            );
          }}
        </DataWrapper>
      </Popover.Dropdown>
      {isModalOpen && <InvitationFlowModal />}
    </Popover>
  );
};
